.textarea {
  &[data-experiment-flag='true'] {
    min-height: auto;
    &:focus {
      min-height: var(--form-textarea-min-height);
    }
  }

  @media (--mobile) {
    padding: 0 !important;
    min-height: 16px !important;
  }
}
